import React from 'react';
import Markdown from 'react-markdown';

import Graphic from 'components/Graphic.js';
import PropTypes from 'prop-types';

export default class BenefitsList extends React.Component {
    static propTypes = {
        list: PropTypes.string.isRequired,
    };

    getListItems(list) {
        let listItems = list.split('\n');
        listItems = listItems.filter(Boolean);
        return listItems;
    }

    getIconRef(listItem) {
        const parts = listItem.split(' ');
        const iconRef = parts[0].replace('[', '').replace(']', '');
        return iconRef;
    }

    render() {
        return (
            <ul className="benefits">
                {this.getListItems(this.props.list).map((listItem, index) => (
                    <li key={index}>
                        <Graphic name={this.getIconRef(listItem)} />
                        <Markdown
                            disallowedElements={['ul', 'ol', 'li', 'p']}
                            unwrapDisallowed
                        >
                            {listItem.replace(
                                `[${this.getIconRef(listItem)}]`,
                                '',
                            )}
                        </Markdown>
                    </li>
                ))}
            </ul>
        );
    }
}
