import React from 'react';
import {graphql} from 'gatsby';

import PropTypes from 'prop-types';

import {getSlug} from 'assets/helper.js';
import BenefitsList from 'components/BenefitsList.js';
import Hero from 'components/Hero.js';
import Layout from 'components/Layout.js';
import MarketingCloudForm from 'components/MarketingCloudForm.js';
import Meta from 'components/Meta.js';
import SalesforceForm from 'components/SalesforceForm.js';
import StepsList from 'components/StepsList.js';
import SubMenu from 'components/SubMenu.js';
import Tiles from 'components/Tiles.js';
import i18n from 'data/i18n.en.json';


export default class ProductsView extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    render() {
        const content = this.props.data.wordpressWpTargeted;
        return (
            <>
                {content.acf.solution && this.props.data.solutionPages.edges
                    .filter(({node}) =>
                        content.acf.solution === node.link
                    )
                    .map(({node}) =>
                        <Layout
                            audience={getSlug(content.acf.audience)}
                            className={`solutions ${node.slug}`}
                            key={node.link}
                            solution={getSlug(content.acf.solution)}
                        >
                            <Meta
                                description={content.acf.subtitle}
                                title={content.title}
                            />
                            <SubMenu
                                audience={this.props.data.audience?.title || ''}
                                buttonLink={content.acf.button_link}
                                buttonLinkSecondary={
                                    content.acf.button_link_secondary
                                }
                                buttonTitle={content.acf.button_title}
                                buttonTitleSecondary={
                                    content.acf.button_title_secondary
                                }
                                formLink={content.acf.marketingcloud_link}
                                solution={content.acf.solution}
                            />
                            <Hero
                                image={content.acf.master_image}
                                subtitle={content.acf.subtitle}
                                title={content.acf.title ?
                                    content.acf.title : content.title
                                }
                                videoURL={content.acf.video_url}
                            />
                            {content.acf.benefits &&
                                <BenefitsList list={content.acf.benefits}/>
                            }
                            {content.acf.steps &&
                                <>
                                    <h3>
                                        {content.acf.steps_title ?
                                            content.acf.steps_title
                                            : i18n.targeted.steps
                                        }
                                    </h3>
                                    <StepsList list={content.acf.steps}/>
                                </>
                            }
                            {content.content &&
                                <section
                                    className="blocks"
                                    dangerouslySetInnerHTML={{
                                        __html: content.content,
                                    }}
                                />
                            }
                            {content.acf.marketingcloud_link &&
                                <MarketingCloudForm
                                    formCopy={content.acf.marketingcloud_copy}
                                    formLink={content.acf.marketingcloud_link}
                                    formTitle={content.acf.marketingcloud_title}
                                />
                            }
                            {(content.acf.salesforce_form && !content.acf.marketingcloud_link) &&
                                <SalesforceForm
                                    form={content.acf.salesforce_form}
                                    formCopy={content.acf.salesforce_form_copy}
                                    formTitle={content.acf.salesforce_form_title}
                                />
                            }
                            {!content.acf.hide_footer_cards &&
                                <Tiles
                                    audience={content.acf.audience}
                                    excludeID={content.wordpress_id}
                                />
                            }
                        </Layout>
                    )
                }
            </>
        );
    }
}

export const query = graphql`
    query($audience: String!, $id: Int!) {
        audience: wordpressWpAudiences(link: {eq: $audience}) {
            title
        }
        solutionPages: allWordpressWpSolutions {
            edges {
                node {
                    link
                    slug
                    title
                }
            }
        }
        wordpressWpTargeted(wordpress_id: {eq: $id}) {
            acf {
                audience
                benefits
                button_link
                button_link_secondary
                button_title
                button_title_secondary
                hide_footer_cards
                marketingcloud_copy
                marketingcloud_link
                marketingcloud_title
                master_image
                salesforce_form
                salesforce_form_copy
                salesforce_form_title
                solution
                steps
                steps_title
                subtitle
                title
                video_url
            }
            content
            title
            wordpress_id
        }
    }
`;
