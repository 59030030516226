import React from 'react';
import PropTypes from 'prop-types';

export default class MarketingCloudForm extends React.Component {
    static propTypes = {
        formCopy: PropTypes.string,
        formLink: PropTypes.string.isRequired,
        formTitle: PropTypes.string,
    };

    render() {
        return (
            <section className="hs-form-container" id="form">
                {this.props.formTitle && <h3>{this.props.formTitle}</h3>}
                {this.props.formCopy && <p>{this.props.formCopy}</p>}
                <a className="cta" href={this.props.formLink}>
                    {'Get in touch'}
                </a>
            </section>
        );
    }
}
