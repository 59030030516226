import React from 'react';
import Markdown from 'react-markdown';

import PropTypes from 'prop-types';

export default class StepsList extends React.Component {
    static propTypes = {
        list: PropTypes.string.isRequired,
    };

    getListItems(list) {
        let listItems = list.split('\n');
        listItems = listItems.filter(Boolean);
        return listItems;
    }

    render() {
        return (
            <ol className="steps">
                {this.getListItems(this.props.list).map((listItem, index) => (
                    <li key={index}>
                        <Markdown
                            disallowedElements={['ul', 'ol', 'li', 'p']}
                            unwrapDisallowed
                        >
                            {listItem}
                        </Markdown>
                    </li>
                ))}
            </ol>
        );
    }
}
