import React from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';
import PropTypes from 'prop-types';

import Graphic from 'components/Graphic.js';

export default class SubMenu extends React.Component {
    static propTypes = {
        audience: PropTypes.string.isRequired,
        buttonForm: PropTypes.bool,
        buttonLink: PropTypes.string,
        buttonLinkSecondary: PropTypes.string,
        buttonTitle: PropTypes.string,
        buttonTitleSecondary: PropTypes.string,
        formLink: PropTypes.string,
        solution: PropTypes.string.isRequired,
    };

    getIconRef(name) {
        const camelised = name
            .toLowerCase()
            .split('-')
            .map((word) => word.replace(/./, (char) => char.toUpperCase()))
            .join('');
        return `icon${camelised}`;
    }

    render() {
        const {
            buttonForm,
            buttonLink,
            buttonLinkSecondary,
            buttonTitle,
            buttonTitleSecondary,
            formLink,
        } = this.props;
        return (
            <nav className="sub-menu">
                <StaticQuery
                    query={graphql`
                        {
                            solutionPages: allWordpressWpSolutions {
                                edges {
                                    node {
                                        link
                                        path
                                        slug
                                        title
                                    }
                                }
                            }
                        }
                    `}
                    render={(data) => (
                        <>
                            {data.solutionPages.edges
                                .filter(
                                    ({node}) =>
                                        this.props.solution === node.link,
                                )
                                .map(({node}) => (
                                    <span className="branding" key={node.slug}>
                                        <Graphic
                                            name={this.getIconRef(node.slug)}
                                        />
                                        <b>
                                            {node.title}
                                            <em>
                                                {`For ${this.props.audience}`}
                                            </em>
                                        </b>
                                    </span>
                                ))}
                            {buttonForm && !buttonLink && !formLink && (
                                <a className="cta" href="#form">
                                    {buttonTitle}
                                </a>
                            )}
                            {buttonLinkSecondary && buttonTitleSecondary && (
                                <a
                                    className="cta secondary"
                                    href={buttonLinkSecondary}
                                >
                                    {buttonTitleSecondary}
                                </a>
                            )}
                            {buttonLink && !formLink && (
                                <a
                                    className="cta"
                                    href={`http://${buttonLink}`}
                                >
                                    {buttonTitle}
                                </a>
                            )}
                            {formLink && (
                                <a className="cta" href={formLink}>
                                    {'Get in touch'}
                                </a>
                            )}
                            {data.solutionPages.edges
                                .filter(
                                    ({node}) =>
                                        // checks for parent page path
                                        // since we do not get this back as a value
                                        node.link.startsWith(
                                            this.props.solution,
                                        ) &&
                                        node.link.length >
                                            this.props.solution.length,
                                )
                                .map(({node}) => (
                                    <Link
                                        className="cta secondary"
                                        key={node.slug}
                                        to={node.path}
                                    >
                                        {node.title}
                                    </Link>
                                ))}
                        </>
                    )}
                />
            </nav>
        );
    }
}
