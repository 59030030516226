import React from 'react';
import PropTypes from 'prop-types';

export default class SalesforceForm extends React.Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        formCopy: PropTypes.string,
        formTitle: PropTypes.string,
    };

    render() {
        return (
            <section className="hs-form-container" id="form">
                {this.props.formTitle && <h3>{this.props.formTitle}</h3>}
                {this.props.formCopy && <p>{this.props.formCopy}</p>}
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.form,
                    }}
                />
            </section>
        );
    }
}
